<template>
    <div class="pd">
        <div class="wrap">
            <header class="navbar">
                <div class="navbar-wrapper">
                    <div class="logo-container">
                        <img src="~@/assets/images/logo.png" alt="logo">
                    </div>
                    <div class="content">
                        <nav class="navbar-menu">
                            <div class="menu-item" @click="goto()">新建</div>
                        </nav>
                    </div>
                </div>
            </header>
            <section class="container">
                <div class="search-box">
                    <input type="text" v-model="searchText" autocomplete="off" placeholder="搜索项目">
                    <span class="iconfont icon-search" @click="fetchQuoteList()"></span>
                </div>
                <div class="quote-list">
                    <div class="item" v-for="(item, index) in quoteList" :key="index">
                        <div class="info">
                            <img :src="item.main_image" width="150" height="100">
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div class="user">{{ item.client_name }}</div>
                        <div class="status">跟进中</div>
                        <div class="action">
                            <div class="action-btns">
                                <div class="btn" @click="handleEdit(item)">编辑</div>
                                <div class="btn" @click="handleDownload(item)">下载</div>
                            </div>
                            <el-progress v-if="isDownloading(item.id)" :indeterminate="true" :show-text="false" :percentage="100" style="margin-top: 1rem;" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getQuoteList, downloadPdf } from '@/api/quote';
import { useRouter } from 'vue-router';

const router = useRouter();

const searchText = ref('');

const quoteList = ref([]);

const downloadStatus = ref({});

const fetchQuoteList = () => {
    const params = {
        name: searchText.value
    };
    getQuoteList(params).then((res)=>{
        quoteList.value = res.data.list;
    })
};

const goto = () => {
    router.push('/design/draw');
};

const handleEdit = (data) => {
    router.push({ path: '/design/draw', query: { id: data.id }});
}

const cancelDownload = (id) => {
    delete downloadStatus.value[id];
}

const handleDownload = async(data) => {
    const id = data.id;
    // 防止重复下载
    if (isDownloading(id)) {
        return
    }
    // 设置下载状态
    downloadStatus.value[id] = true;

    try {
        const res = await downloadPdf(id)
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.download = 'download.pdf';
        a.href = url;
        a.click();
        URL.revokeObjectURL(url)
    } catch (error) {
        console.error('下载失败:', error)
    } finally {
        cancelDownload(id)
    }
}

// 判断是否正在下载
const isDownloading = (id) => {
    return downloadStatus.value[id] === true
}

onMounted(() => {
    fetchQuoteList();
})
</script>

<style scoped>
.search-box {position: relative;width: 100%;height: 3rem;border: 1px solid #ffffff;border-radius: 0.5rem;padding: 0 1rem;flex-shrink: 0;}
.search-box input {width: 100%;height: 100%;color: #ccc;border: none;background-color: transparent;font-size: 1rem;}
.search-box span {position: absolute;right: 0;width: 2.5rem;line-height: 3rem;text-align: center;height: 100%;color: #ccc;font-size: 1.2rem;}

.quote-list{height: calc(100vh - 14rem);overflow-y: auto;}
.quote-list .item{display: flex; align-items: center; justify-content: space-between;padding: 1rem 0;border-bottom: 1px dashed #ccc;}
.quote-list .item>div{flex: 1;text-align: center;}
.quote-list .item .info{flex: 2 !important;display: flex; align-items: center;}
.quote-list .item .info .name{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;max-width: 15rem;color: yellow;margin-left: 2rem;}
.quote-list .item .user{color: #fff;}
.quote-list .item .status{color: #fff;}
.quote-list .item .action .action-btns{display: flex;justify-content: center;}
.quote-list .item .action .btn{color: #ffffff;padding: 0 1rem;border-radius: 0.5rem;background-color: rgba(120, 120, 120, 0.6);height: 2.5rem;line-height: 2.5rem;border: 1px solid #fff; margin-right: 5px;}
</style>