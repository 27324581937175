import request from '@/utils/request';

export const getQuoteList = (params) =>
request({
	url: '/quote/lists',
	method: 'get',
	params
})

export const getQuoteDetail = (id) =>
request({
	url: '/quote/detail',
	method: 'get',
	params: {
        id: id
    }
})

export const saveQuote = (data) =>
request({
	url: '/quote/save',
	method: 'post',
	data
})

export const uploadAttachment = (data) =>
request({
	url: `/quote/uploadAttachment`,
	method: 'post',
	data
})

export const downloadPdf = (id) =>
request({
    url: '/quote/download',
    method: 'get',
    responseType:'blob',
    params: {
        id: id
    }
})